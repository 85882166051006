exports.components = {
  "component---src-pages-docs-component-styling-js": () => import("./../../../src/pages/docs/component-styling.js" /* webpackChunkName: "component---src-pages-docs-component-styling-js" */),
  "component---src-pages-docs-create-component-js": () => import("./../../../src/pages/docs/create-component.js" /* webpackChunkName: "component---src-pages-docs-create-component-js" */),
  "component---src-pages-docs-environment-setup-js": () => import("./../../../src/pages/docs/environment-setup.js" /* webpackChunkName: "component---src-pages-docs-environment-setup-js" */),
  "component---src-pages-docs-hierarchy-visualization-js": () => import("./../../../src/pages/docs/hierarchy-visualization.js" /* webpackChunkName: "component---src-pages-docs-hierarchy-visualization-js" */),
  "component---src-pages-docs-installation-js": () => import("./../../../src/pages/docs/installation.js" /* webpackChunkName: "component---src-pages-docs-installation-js" */),
  "component---src-pages-docs-introduction-js": () => import("./../../../src/pages/docs/introduction.js" /* webpackChunkName: "component---src-pages-docs-introduction-js" */),
  "component---src-pages-docs-new-user-tutorial-js": () => import("./../../../src/pages/docs/new-user-tutorial.js" /* webpackChunkName: "component---src-pages-docs-new-user-tutorial-js" */),
  "component---src-pages-docs-oauth-config-js": () => import("./../../../src/pages/docs/oauthConfig.js" /* webpackChunkName: "component---src-pages-docs-oauth-config-js" */),
  "component---src-pages-docs-routes-js": () => import("./../../../src/pages/docs/routes.js" /* webpackChunkName: "component---src-pages-docs-routes-js" */),
  "component---src-pages-docs-save-and-export-js": () => import("./../../../src/pages/docs/save-and-export.js" /* webpackChunkName: "component---src-pages-docs-save-and-export-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourteam-index-js": () => import("./../../../src/pages/ourteam/index.js" /* webpackChunkName: "component---src-pages-ourteam-index-js" */)
}

